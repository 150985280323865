import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/crZtC9l_smU">
    <SEO title="Do Not Murder - The Big 10" />
  </Layout>
)

export default SermonPost
